let isOpen = false;
const body = document.querySelector("body");
const handleScrollLock = () => {
  isOpen = !isOpen;
  isOpen
    ? body.classList.add("scroll-lock")
    : body.classList.remove("scroll-lock");
};

document
  .querySelector(".js-menu__side-bar")
  .addEventListener("click", handleScrollLock);

document
  .querySelectorAll(".js-menu_side-bar__menu > ul > li a")
  .forEach(function (item) {
    //console.log(item);
    item.addEventListener("click", function (e) {
      //console.log(e.currentTarget);
      if (window.innerWidth < 1280) {
        if (e.currentTarget.classList.contains("has-submenu")) {
          e.preventDefault();
          e.currentTarget.parentNode.parentNode.classList.toggle("open-menu");
        }
      }
    });
  });
